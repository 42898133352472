import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
     <h1>Website under construction 
     </h1>
     <h1>InfinitudeX</h1>
    </div>
  );
}

export default App;
